/* eslint-disable react/no-unescaped-entities */
import { useCallback, useEffect, useState } from 'react'
import FoxDelegate from './FoxUI'
import { useApp } from '../../hooks/useApp'
import {
    FOX_DELEGATE_CURRENCY_MAP,
    SupportedBlockchain,
    type SupportedToken,
} from '../../types'
import Loading from '../../components/loading/Loading'
import { ALLOWANCE_MAX_VALUE, formatBalance } from '../../utils'
import { useModal } from '../../contexts/ModalContext'
import {
    ErrorMessage,
    getErrorMessage,
    PostErrorMessage,
    PostOperationType,
} from '../../error'
import CoinSelector from '../../components/coins/CoinSelector'
import usePostMessage from '../../hooks/usePostMessage'
import { useSDK } from '@metamask/sdk-react'
import { isMobile } from 'react-device-detect'
import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import CloseSVG from '@mui/icons-material/Close'
import useBlockchain from '../../hooks/useBlockchain'
import { useLocation } from 'react-router-dom'
import {
    getChainIdByName,
} from '@baanx/common/network/blockchain/config'
import config from '../../config'
import MetaMaskButton from '../../components/metamask/MetaMaskButton'
import { type ethers } from 'ethers'
import { Blockchains } from '../../components/blockchain/Utils'

let strictInit = false
let connectedAddress = ''
let hasSelectedAccount = false

// eslint-disable-next-line @typescript-eslint/promise-function-async
const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))
async function withRetry<T>(
    action: () => Promise<T>,
    retries: number = 2
): Promise<T> {
    try {
        return await action()
    } catch (e) {
        if (retries === 0) {
            throw e
        }
        await sleep(1000)
        return await withRetry(action, retries - 1)
    }
}
const FoxContainer = () => {
    const location = useLocation()
    const blockchainParam =
        new URLSearchParams(location.search).get('blockchain') ??
        SupportedBlockchain.LINEA
    /** STATES **/
    const [coinSelect, setCoinSelect] = useState(false)
    const [isContinuous, setContinuous] = useState(true)
    const [amount, setAmount] = useState('0.00')
    const [isAlertOpen, setAlertOpen] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [control, setControl] = useState(false)
    const [delegationProcessing, setDelegationProcessing] = useState(false)
    const [delegationSuccess, setDelegationSuccess] = useState(false)
    const [shouldRender, setShouldRender] = useState(isMobile && !control)
    const [mobileLoading, setMobileLoading] = useState(false)
    const [hasSiwe, setSiwe] = useState(false)
    const [siweSignature, setSiweSignature] = useState<ethers.Signature>()
    const [preparedMessage, setPreparedMessage] = useState<string | undefined>()

    /** CUSTOM HOOKS **/
    const { sdk, ready, provider } = useSDK()
    const {
        selectedNetwork,
        setSelectedCurrency,
        selectedCurrency,
        blockchain,
        loadUserInfo,
        selectedWallet,
        auditThenDelegate,
        preferredFiatCurrency,
        exchangeRate,
        cardUsageTitle,
        siwe,
    } = useApp(blockchainParam as SupportedBlockchain)

    const { getBalance } = useBlockchain()
    const { hide } = useModal()
    const { postSuccessMessage: postCloseMessage } = usePostMessage(
        PostOperationType.CLOSE
    )
    const { postCustomMessage } = usePostMessage(
        PostOperationType.WALLET_BALANCES
    )
    const ignoreErrors = ['pending', 'correct address']

    const handleReceivedMessage = (event: MessageEvent) => {
        if (event?.data?.type === 'approvalUI') {
            console.log('Received approvalUI message', event?.data)
            const isProceed = event.data.data?.action === 'proceed'
            setShouldRender(isProceed)
            setMobileLoading(!isProceed)
        }
    }
    const { postSuccessMessage, postErrorMessage } = usePostMessage(
        PostOperationType.DELEGATE_FUNDS,
        ignoreErrors,
        handleReceivedMessage
    )

    /** HANDLERS AND FUNCTIONS */
    const connectMobileMetamask = async () => {
        // this event must be notified every time the user clicks "Connect Metamask" from the mobile version of the delegation app
        postCustomMessage({
            type: PostOperationType.EVENT,
            data: {
                name: 'fox_connect_metamask_mobile_clicked',
            },
        })
        try {
            setMobileLoading(true)

            await blockchain.connectMetamask(selectedNetwork)
            if (connectedAddress === '') {
                // user token must be only used once
                connectedAddress = await loadUserInfo()
            }
            await withRetry(async () => {
                await reportBalances(connectedAddress)
            })
            
            setControl(true)
            setShouldRender(false)
            
        } catch (error: any) {
            postErrorMessage(
                error.message.includes(ErrorMessage.WALLET_ERROR)
                    ? PostErrorMessage.WALLET_CONNECTION_ERROR
                    : PostErrorMessage.USER_DATA,
                error
            )
            handleError(error)
        }
        finally {
            setMobileLoading(false)
        }

    }
    const coinHandler = () => {
        postCustomMessage({
            type: PostOperationType.EVENT,
            data: {
                name: 'fox_connect_token_selector_opened',
                currentToken: selectedCurrency,
            },
        })
        setCoinSelect(true)
    }
    const selectCoinHandler = async (coin: SupportedToken) => {
        if (!selectedWallet || !selectedNetwork) return
        if (coin) {
            const previousToken = selectedCurrency
            postCustomMessage({
                type: PostOperationType.EVENT,
                data: {
                    name: 'fox_connect_token_selected',
                    previousToken,
                    currentToken: coin,
                },
            })
            setSelectedCurrency(coin)
            try {
                await blockchain.refreshConnection(
                    selectedNetwork,
                    coin,
                    selectedWallet
                )
            } catch (error: any) {
                postErrorMessage(
                    PostErrorMessage.WALLET_CONNECTION_ERROR,
                    error
                )
                handleError(error)
                return
            }
        }

        setCoinSelect(false)
    }

    const delegateHandler = async (amount: string) => {
        if (!selectedCurrency) return
        try {
            setDelegationProcessing(true)

            postCustomMessage({
                type: PostOperationType.EVENT,
                data: {
                    name: 'fox_connect_approve_clicked',
                    isAutomatic: isContinuous,
                    amount,
                    token: selectedCurrency,
                },
            })
        
            await auditThenDelegate(
                isContinuous ? ALLOWANCE_MAX_VALUE : amount,
                isMobile && siweSignature && preparedMessage ? {
                    signature: siweSignature,
                    preparedMessage,
                } :  undefined
            )
            postSuccessMessage()
            setDelegationProcessing(false)
            setDelegationSuccess(true)
        } catch (error) {
            postErrorMessage(PostErrorMessage.DELEGATE_FUNDS, error)
            handleError(error)
            setDelegationProcessing(false)
        } finally {
            setDelegationProcessing(false)
        }
    }

    const handleError = useCallback(
        (error: any) => {
            console.error(error)
            setAlertOpen(true)
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setAlertMessage(getErrorMessage(error))
            hide()
        },
        [hide]
    )

    const reportBalances = useCallback(
        async (address: string) => {
            const tokens = [
                ...FOX_DELEGATE_CURRENCY_MAP[
                    selectedNetwork as unknown as SupportedBlockchain
                ],
            ]
            const [ethBalance, balances] = await Promise.all([
                getBalance(address),
                Promise.all(
                    tokens.map(
                        async (token) =>
                            await blockchain.balanceOf(address, token)
                    )
                ),
            ])
            const balanceMap = tokens.reduce((acc, token, index) => {
                acc[token] = balances[index]
                return acc
            }, {})

            const allBalances = {
                ...balanceMap,
                eth: ethBalance,
            }

            postCustomMessage({
                type: PostOperationType.WALLET_BALANCES,
                data: {
                    address,
                    balances: allBalances,
                },
            })
            // more realistic as we can check if something was wrong with the balances report
            if (window.location.host.includes('localhost')) {
                setTimeout(() => {
                    window.postMessage(
                        { type: 'approvalUI', data: { action: 'proceed' } },
                        '*'
                    )
                }, 1500)
            }
        },
        [blockchain, getBalance, postCustomMessage, selectedNetwork]
    )

    const rate =
        exchangeRate?.[selectedCurrency as string]?.[
            preferredFiatCurrency ?? ''
        ]
    const fiatBalance = rate * Number(blockchain.tokenBalance)

    const onCloseHandler = () => {
        postCloseMessage()
    }

    const siweHandler = async () => {
        const siweData = await siwe()
        setSiweSignature(siweData.signature)
        setPreparedMessage(siweData.preparedMessage)
        setSiwe(true)
    }
    const switchNetworkHandler = async () => {
        try {
            await provider?.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: getChainIdByName(selectedNetwork, config) }], // chainId must be in hexadecimal numbers
              })
        } catch (error) {
            console.error('Error while changing network.', error)
        }
    }
    useEffect(() => {
        // this typeod check is required because this value can shortly be assigned to random objects
        if (
            !hasSelectedAccount &&
            blockchain.getAccount() &&
            typeof blockchain.getAccount() === 'string'
        ) {
            postCustomMessage({
                type: PostOperationType.EVENT,
                data: {
                    name: 'fox_connect_selected_account',
                    address: blockchain.getAccount(),
                },
            })
            hasSelectedAccount = true
        }
    }, [blockchain, postCustomMessage])

    const switchAccountHandler = async () => {
        // must be fired when the user clicks on the account selector
        postCustomMessage({
            type: PostOperationType.EVENT,
            data: {
                name: 'fox_connect_account_selector_opened',
                address: blockchain.getAccount(),
            },
        })
        await blockchain.switchAccount()
        hasSelectedAccount = false
        if (selectedNetwork && selectedCurrency && selectedWallet) {
            await blockchain.refreshConnection(
                selectedNetwork,
                selectedCurrency,
                selectedWallet
            )
        }
    }

    useEffect(() => {
        if (
            !selectedCurrency ||
            !selectedNetwork ||
            !selectedWallet ||
            isMobile
        ) {
            // this event must be notified every time the app is opened
            // it will be spammed if put in the main useEffect
            postCustomMessage({
                type: PostOperationType.EVENT,
                data: {
                    name: 'fox_connect_opened',
                },
            })
            return
        }

        if (sdk && provider && ready && !strictInit) {
            // this event must be notified every time the app is opened
            // it will be spammed if put in the main useEffect
            postCustomMessage({
                type: PostOperationType.EVENT,
                data: {
                    name: 'fox_connect_opened',
                },
            })
            strictInit = true
            void blockchain
                .connectMetamask(selectedNetwork)
                .then(loadUserInfo)
                .then(reportBalances)
                .catch((error) => {
                    console.error('error:', error)
                    postErrorMessage(
                        error.message.includes(ErrorMessage.WALLET_ERROR)
                            ? PostErrorMessage.WALLET_CONNECTION_ERROR
                            : PostErrorMessage.USER_DATA,
                        error
                    )
                    handleError(error)
                })
        }
    }, [
        blockchain,
        handleError,
        loadUserInfo,
        postCustomMessage,
        postErrorMessage,
        provider,
        ready,
        reportBalances,
        sdk,
        selectedCurrency,
        selectedNetwork,
        selectedWallet,
    ])


    const showLoading =
        !sdk?.getProvider() ||
        blockchain.isLoading ||
        !selectedNetwork ||
        (isMobile ? mobileLoading : !shouldRender)

    const currentChainId = sdk?.getProvider()?.getChainId()
    const targetChainId = getChainIdByName(selectedNetwork, config)

    console.log("currentChainId", currentChainId)
    console.log("targetChainId", targetChainId)
    console.log("showLoading", showLoading)
    console.log("isMobile", isMobile)
    console.log("control", control)
    
    const shouldSwitchMobile =
        isMobile && control && currentChainId !== targetChainId && !showLoading

    const shouldConnectMobile = isMobile && !control && !showLoading

    const shouldRenderMain =
        !showLoading &&
        (!isMobile || (!shouldConnectMobile && !shouldSwitchMobile))

    return (
        <div style={{ padding: '1.5rem' }}>
            {(coinSelect && selectedNetwork && (
                <CoinSelector
                    coins={FOX_DELEGATE_CURRENCY_MAP[selectedNetwork]}
                    setSelectedCurrency={selectCoinHandler}
                    selectedNetwork={selectedNetwork}
                />
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            )) || (
                    <>
                        <Grid
                            rowGap={'1.5rem'}
                            alignItems={'center'}
                            item
                            xs={12}
                            container
                        >
                            <Grid item xs={1} />
                            <Grid item xs={10}>
                                <Typography align="center" variant="h5">
                                    {!showLoading && 'Set a spending limit'}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} container justifyContent="end">
                                <IconButton
                                    color="inherit"
                                    onClick={onCloseHandler}
                                >
                                    <CloseSVG />
                                </IconButton>
                            </Grid>
                        </Grid>
                        {shouldConnectMobile && (
                            <Box mt={6} height="100vh">
                                <MetaMaskButton
                                    onClick={connectMobileMetamask}
                                ></MetaMaskButton>{' '}
                            </Box>
                        )}
                        {shouldSwitchMobile && (
                            <Box mt={6} height="100vh">
                                <Button
                                    fullWidth
                                    onClick={switchNetworkHandler}
                                    startIcon={Blockchains.linea.icon}
                                    key={'switchToLinea'}
                                    variant="contained"
                                    color="secondary"
                                >
                                    Switch to Linea
                                </Button>
                            </Box>
                        )}
                        {(showLoading && (
                            <Box height="100vh">
                                <Loading />
                            </Box>
                        )) ||
                            (shouldRenderMain && (
                                <>
                                    <FoxDelegate
                                        alertText={alertMessage}
                                        openAlert={isAlertOpen}
                                        handleCloseAlert={() => {
                                            setAlertMessage('')
                                            setAlertOpen(false)
                                            hide()
                                        }}
                                        coinHandler={coinHandler}
                                        accountId={
                                            blockchain.getAccount() ?? ''
                                        }
                                        selectedCurrency={
                                            selectedCurrency as any
                                        }
                                        selectedNetwork={selectedNetwork as any}
                                        fiatBalance={formatBalance(
                                            fiatBalance.toString()
                                        )}
                                        tokenBalance={formatBalance(
                                            blockchain.tokenBalance
                                        )}
                                        delegate={
                                            isMobile && !hasSiwe
                                                ? siweHandler
                                                : delegateHandler
                                        }
                                        isContinuous={isContinuous}
                                        setContinuous={setContinuous}
                                        amount={amount}
                                        setAmount={setAmount}
                                        preferredFiatCurrency={
                                            preferredFiatCurrency
                                        }
                                        cardUsageTitle={cardUsageTitle}
                                        delegationProcessing={
                                            delegationProcessing
                                        }
                                        delegationSuccess={delegationSuccess}
                                        switchAccountHandler={
                                            switchAccountHandler
                                        }
                                        allowance={blockchain.allowance}
                                        approveTitle={
                                            isMobile && !hasSiwe
                                                ? 'Sign with Ethereum'
                                                : 'Approve now'
                                        }
                                    />
                                </>
                            ))}
                    </>
                ) || <></>}
        </div>
    )
}

export default FoxContainer
